import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import VisionCarousel from '../components/Vision Carousel';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "St. Augustine Community Health";

},[]);

  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Who We Are</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>

                 <p className='home-p'><strong>St. Augustine Community Health Centre</strong> as known as SACH has been in existence since 2015. It’s a registered community-based organization started by members to increase access to quality sexual and reproductive health services and information. Our target is the vulnerable and poor adolescents, young women and men. 
</p>
<p className='home-p'>We are currently operating in the districts of Mbarara, Mbarara City, Isingiro, Ntangamo, Bushenyi and Sheema. We exist to improve access to quality sexual and reproductive health (SRH) information and services to the less privileged people in our communities (adolescent girls and young women, women living in poverty, girls and women living in slums, persons with disabilities, and key and priority populations). </p>

<p className='home-p'>We support provision of SRH services through partnership with public and private healthcare facilities that provide modern family planning, post abortion care, HIV/AIDS care and treatment, STD/STI management and cervical cancer screening services. Through collaboration and partnership with district leaders (health, and community departments), public and private healthcare facilities, community members, and mobilization and referral through community-based mobilisers, we have supported less privileged community members to have increased access to SRH services in a manner that meets their needs and interest. </p>
 <p className='home-p'>In 2021, we supported HIV testing among 600 young women and girls, 24(4%) of whom tested positive, and were linked to HIV care and treatment. We also implemented a project scaling up Community ART Adherence support, Retention and ART treatment literacy for PLHIV in the UPDF Makenke barracks army hospital. Over six months of implementing this project, 26 uniformed men and 16 pregnant mothers who had been lost to follow up were returned into care. </p>
     <p className='home-p'>Further, over a period of 39 months (October 2018 to December 2021), we successfully implemented a Mbarara-Do-It Safe (MBA-DIS) project with funding from Safe abortion action fund (SAAF)/IPPF which supported 2,365 women and girls to access post abortion care services over and above the set target of 1,852. The project also supported 5,236 women and young girls to have increased knowledge on prevention of unwanted pregnancy and unsafe abortion (over and above set target of 5,094). Further, successful implementation of this project was associated with 391 unsafe abortions averted, 29 maternal deaths prevented, and 998 unwanted pregnancies prevented.</p>    
        </div>

    </section>
     <section className='section-cover gallery-cover ' >
       {/* <h2 className="home-h2 gallery-h2">Newsletter</h2> */}
      <div className='content-container gallery gallery-a'>
                                   <Link to='newsletter' className='home-btn' onClick={scrollFunc}>Newsletter</Link>
      </div>

    </section>
     {/* <section className='section-cover gallery-cover ' >
       <h2 className="home-h2 gallery-h2">Current Events</h2>
      <div className='content-container gallery gallery-a'>
        <article className='gallery-content gallery-content-a'>
          <img src='./images/smcn1.jpg' className='gallery-image' alt='image'></img>
        </article>

         <article className='gallery-content gallery-content-a'> 
          <img src='./images/smcn2.jpg' className='gallery-image' alt='image'></img>
         </article>
      </div>

    </section> */}
<div className='vision-mission-content vision-mission-content-a'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/40B.jpg' alt='founder' className='founders-image'></img>
                          <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3>
                          {/* <div className='vm-line'> </div> */}
                          <p>Founder</p>

                    </article>

                 </div>
  <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.62), rgba(8, 6, 4, 0.81)), url("./images/8.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Mission</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 To promote access to quality sexual and reproductive health services and information to the underserved communities through capacity building, systems strengthening and empowerment.
</p>
        </div>
    </section>
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
    
    
  
    </>
  )
}

export default Home