import Carousel from 'react-bootstrap/Carousel';

function VisionCarousel() {
  return (
    <Carousel indicators={false} className='vision-slides'>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/1A.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/2.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/3.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

        <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/5A.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/6A.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/7.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/8.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/9.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/10.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/11.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/12.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/13.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/14.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

        <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/15.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/16.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/17.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/18.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/19.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/20.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/21.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/22.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/24.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
     

    </Carousel>
  );
}

export default VisionCarousel;