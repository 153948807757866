import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = "About Us | St. Augustine Community Health";

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                      
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>To promote access to quality sexual and reproductive health services and information to the underserved communities through capacity building, systems strengthening and empowerment.</p>

                    </article>

                    


                 </div>

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                        <MissionCarousel></MissionCarousel>
                    </article>

                    <article className='vision-mission-info vision-mission-info1'>
                      
                          <h3 className='title-h3'>Our Core Values</h3>
                          
                           <div className='vm-line'></div>
                          <ul>
                          <li>Client-centered.</li>  
<li>Confidentiality.</li>
<li>Professionalism.</li>
<li>Result-oriented.</li>
<li>Passion.</li>
<li>Respect for human dignity.</li>

                          </ul>
                          
                          

                    </article>

                    {/* <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article> */}


                 </div>
          </div>
    </section>
    <section className="cert-cover">
      
                          
                           {/* <div className='vm-line'></div> */}
    
        <div>
            <h3 className='title-h3 title-h3-c'>Governance Structure</h3>
          <img src='images/49.jpg' className='certification-image'></img>
        </div>

         {/* <div>
          <img src='images/19.jpg' className='certification-image'></img>
        </div> */}

    </section>

    </>
  )
}

export default WhoWeAre