import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaTiktok} from 'react-icons/fa';
import Subscribe from './Subscribe';
// import TwitterContainer from './Twitter';
// import TwitterTimelineEmbed from 'react-tweet-embed'

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.82), rgba(8, 6, 4, 0.91)), url("./images/8.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>





             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                       

                         <div className='footer-link'>
                              <Link to='/' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>
                        <div className='footer-link'>
                              <Link to='current-events' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Current Events</p></Link> 
                        </div>
                        <div className='footer-link'>
                              <Link to='about' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>About Us</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='programs' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Programs</p></Link> 
                        </div>
                         <div className='footer-link'>
                                <Link to='projects' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Projects</p></Link>
                        </div>
                        <div className='footer-link'>
                                <Link to='gallery' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Gallery</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='contact' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Write To Us</p></Link> 
                        </div>

                        
                    </div>

             </div>

            

             {/* <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div> */}

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Location</h3>
                      <p>
                      Ntare Road Opposite Ntare School, Mbarara City North, Mbarara City, Uganda<br></br>
                      P. O. Box 898, Mbarara - Uganda
                        </p>

                    </div>

                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                     +256703 962281 <br></br> +256782 141191
                      </p>

                    </div>

                    <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='contact' onClick={scrollFunc} className='email-link'>  <p>st.augustinehealthcentre@gmail.com<br></br>info@sachmbarara.org</p></Link> 
                      
                      </div>

                   
                      

             </div>

          <div className='footer-inner-content'>


                     
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                                 <span>
<a href="https://www.facebook.com/groups/932363637209224" target="_blank" className='social-link' rel='noreferrer'><FaFacebookSquare></FaFacebookSquare></a>
                                 </span>
                                 <span>
<a href="https://twitter.com/SACHMbarara" target="_blank" className='social-link' rel='noreferrer'><FaTwitterSquare></FaTwitterSquare></a>
                                 </span>

                                 <span>
<a href="https://www.tiktok.com/@sachmbarara" target="_blank" className='social-link' rel='noreferrer'><FaTiktok></FaTiktok></a>
                                 </span>  
                         </div>
                        
                        
                      </div>

                      <div className='footer-social-icons'>
                         <Subscribe></Subscribe>
                       {/* <TwitterContainer></TwitterContainer> */}
                       

                        </div>

             </div>
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> St. Augustine Community Health | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer