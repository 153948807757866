import Carousel from 'react-bootstrap/Carousel';

function TelecomCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="First slide"
        />

      </Carousel.Item>
  
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/4.jpeg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/18.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/19.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/20.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/21.jpeg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/22.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
    </Carousel>
  );
}

export default TelecomCarousel;