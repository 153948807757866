import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/23.jpeg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/24.jpg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25.jpg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/26.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/27.png"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/28.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       
    </Carousel>
  );
}

export default PowerCarousel;