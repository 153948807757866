import React, {useEffect} from 'react'
import Tool1 from '../components/Tool1';
import Tool2 from '../components/Tool2';
import Tool3 from '../components/Tool3';
import Tool4 from '../components/Tool4';
import Tool5 from '../components/Tool5';
import Tool6 from '../components/Tool6';
import Tool7 from '../components/Tool7';
import Tool8 from '../components/Tool8';
import Tool9 from '../components/Tool9';
import Tool10 from '../components/Tool10';

const Gallery = () => {
  useEffect(()=>{
document.title = 'Gallery | St. Augustine Community Health';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Gallery</h2>
      <div className='content-container gallery'>
        <article className='gallery-content'>
          <img src='./images/4.jpg' className='gallery-image' alt='image'></img>
           <p>Our Behavioral Change & Communication Officer distributing free condoms to people he found in a bar
</p>
         </article>

         <article className='gallery-content'> 
         <Tool1></Tool1>
          {/* <img src='./images/d1.jpg' className='gallery-image' alt='image'></img> */}
           <p>The Founder & Executive Director training Village Health Team members about community family planning </p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/d1.jpg' className='gallery-image' alt='image'></img> */}
           <Tool2></Tool2>
           <p>During big concerts that attract thousands we send in teams to distribute condoms and have a one on one discussion about prevention of UN safe abortion</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/9.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool2></Tool2> */}
           <p>Mbarara District Community Development Officer giving opening remarks during a stake holders meeting held in Mbarara about post abortion care project with funds from SAAF</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/9.jpg' className='gallery-image' alt='image'></img> */}
           <Tool3></Tool3>
           <p>The District Senior Health Educator for Mbarara district Mr. Kamugisha Fredrick closing the meeting between SACH and its stake holders on the new project of Unwanted pregnancy prevention, safe abortion options and post abortion care. </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/15.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool3></Tool3> */}
           <p>An ART Incharge Ruti health centre 11 Mbarara showing the differences between PEP and PrEP drugs</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/15.jpg' className='gallery-image' alt='image'></img> */}
           <Tool4></Tool4>
           <p>Our community mobilizers for the HIV/TB campaign project</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/15.jpg' className='gallery-image' alt='image'></img> */}
           <Tool5></Tool5>
           <p>A training for KPs this is under the training grant SACH received from TASO under the ICWEA supervision</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/15.jpg' className='gallery-image' alt='image'></img> */}
           <Tool5></Tool5>
           <p>A training for KPs this is under the training grant SACH received from TASO under the ICWEA supervision</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/26.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool5></Tool5> */}
           <p>Executive Director at. Breastfast cafe on quality of maternal health and safe abortion contribution</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/26.jpg' className='gallery-image' alt='image'></img> */}
           <Tool6></Tool6>
           <p>We hold monthly free clinics for cervical and prostate cancer screening</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/23.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool6></Tool6> */}
           <p>A health education session in progress at our clinic sitted at SACH headquarters</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/22.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool6></Tool6> */}
           <p>Flagging off Research Assistants during our research on the use of PrEP</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/22.jpg' className='gallery-image' alt='image'></img> */}
           <Tool7></Tool7>
           <p>Field visit for SACH staff funding from SAAF</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/16.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool7></Tool7> */}
           <p>Director during one of the many radio talk shows</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/35.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool7></Tool7> */}
           <p>SACH staff in a saloon talking to young women and girls about Dangers of unsafe abortion</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/35.jpg' className='gallery-image' alt='image'></img> */}
           <Tool8></Tool8>
           <p>In the field work ing with compassion</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/46.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool8></Tool8> */}
           <p>World AIDS Day 2022. We were main partners together with TASO and Vision group</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/44.jpg' className='gallery-image' alt='image'></img> */}
           <Tool10></Tool10>
           <p>SACH director in Thailand</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/44.jpg' className='gallery-image' alt='image'></img> */}
           <Tool9></Tool9>
           <p>Poster presentation in Thailand</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/40.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool9></Tool9> */}
           <p>The ICFP in Pattya Thailand was funded by SAAF</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/37.jpg' className='gallery-image' alt='image'></img>
           {/* <Tool9></Tool9> */}
           <p>Talk show about post abortion care</p>
         </article>

        


      </div>

    </section>
  )
}

export default Gallery