import React from 'react'

let ValuesData = [

{title: "1. General medical care",      
content: "This involves diagnosis of simple and complicated aliments. Our team of expert clinicians are always available to make sure our patients and clients receive first class care with a good turn around time and enough time to understand their conditions. Referrals are always made through our already established friendship network of specialists ready through a single phone call so that our people don’t have to suffer finding specialized care in case of need."
},

{title: "2.	Comprehensive laboratory services",      
content: "Our well-equipped laboratory offers a range of high-quality services. We have routine quality improvement check done with in to calibrate our tests and external agencies like the Uganda Virus research institute, the Allied Health professional’s council and the district health office."
},
{title: "3.	Post abortion care",      
content: "We have been excellent providers of post abortion care all the years. Our community has lots of young people and poor women who use crude methods to terminate their un-pregnancies using crude methods and will constantly need post abortion care. We also educate the community of the ways they can prevent the unwanted pregnancies as well as counselling those who get the un-wanted pregnancies. Throughout the whole process we are present to help. Post abortion family planning is always the emphasis to avoid repeat un-wanted pregnancies."
},
{title: "4.	Cervical cancer screening",      
content: "We have championed offering cervical cancer services to our women in the community. This service is offered is offered daily at the clinic at a small fee. We however have a free cervical cancer screening every month (usually the last Thursday and Friday) for those who cannot afford to pay. Referrals for positives are sent for PAP smear or Cryotherapy. We hope in future to have a cryotherapy machine of our own."
},
{title: "5. Male medical circumcision",
content:"We offer safe male circumcision to boys of all ages. We join a fight to reduce the spread of HIV/AIDS and other common STDs. We offer this throughout the week."
},
{title: "6.	Antenatal care and deliveries",
content:"We offer antenatal services and book our midwives for safe deliveries. We hope in future to have a fully functioning theatre for comprehensive deliveries with complications. We also take care of postnatal mothers and neonatal health."
},
{title: "6.	Antenatal care and deliveries",
content:"We offer antenatal services and book our midwives for safe deliveries. We hope in future to have a fully functioning theatre for comprehensive deliveries with complications. We also take care of postnatal mothers and neonatal health."
},
{
title:"7. Prostate screening tests",
content:"We check for any signs of prostate diseases and specifically prostate cancer because it becoming very rampant among men. We also do prostate cancer screening monthly and free of charge as part of our awareness creation."
},
{
title: "8. Family planning",
content:"We offer comprehensive modern family planning information in terms of counselling and services. We offer short term, long term and permanent methods. These are the products on the market now; Pills (combined oral pills, progesterone only pills, emergency pills), implants (Implanon and Jadelle), Intra uterine devices (copper T and Hormonal IUD), Injectables (depo and Sayana press) and permanent methods (tuba ligation and Vasectomy). We also have free condoms (male and female) for anyone who walks in and those for cash as well as free sex lubricants, moon beads."
},
{
title: "9. Immunization",
content:"We have been participating in routine immunization of conditions people tend to neglect. These include Hepatitis B, Anti-rabies, Human papiroma virus vaccination (Cervical cancer) and Yellow fever among others. We wish to start childhood immunization soon."
},
{
title: "10. HIV/AIDS counselling and Testing",
content:"We carry out routine counselling and testing services for individuals, couple and corporations. We strive to offer the best services with quality control by the Uganda Virus research institute. Results are always correct and instant."
},
{title:"11.	STD/STI management",
content:"As part of our reproductive health program we offer comprehensive management to sexuality diseases. We also take time to educate our clients on prevention, drug misuse and resistance in treatment."
},
{
    title:"12. Ultra sound scan services",
content:"We offer ultra sound scan services for our clients especially those who need post abortion care and our pregnant women for best outcomes."
},
{title:"13.	Youth friendly services",
content:"We have made sure young people are treated in a special way because they have lots of challenges. We have school health programs where we talk to these young people and have dedicated young persons who handle their health issues."
},
{title:"14.	Couple counselling",
content:"We offer counselling to couples who have issues as part of our contribution towards prevention of domestic violence. During this program we partner with Radio West and we participate in a love show called Trespass where all sexual issues are talked about through question we answer."
},{
    title:"15. Fertility management",
content:"We offer fertility screenings and management. We also help couple discover their fertility days, issues of ovulation and planning for pregnancies."
}
];

export default ValuesData;