import React, {useEffect} from 'react';
import PowerCarousel from '../components/Mission Carousel';
import TelecomCarousel from '../components/Vision Carousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = "Our Programs | St. Augustine Community Health";

},[]);


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Programs</h2>
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Sexual & Reproductive Health Services</h3>
           
                     <p>
    {/* We have brought you both new and refurbished medical equipment to your doorsteps in Uganda. We understand the convergence point between high-quality medical equipment & affordability. You'll find both at ELK'S Medicals Limited. */}
                   
                     </p>
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Sexual & Reproductive Health Information</h3>
                     
                     <p>
                {/* At ELK'S Medicals Limited, you'll find a robust outsourcing network & logistics infrastructure especially on refurbished medical equipment from The USA. */}
                     
                     </p>
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Medical Equipment Rental Services </h3>
                       
                       <p>
              Medical equipment is rented for a variety of reasons. The most common is to meet unusually high demand, some facilities may require equipment while waiting for purchases to arrive or while items are being serviced. Others may prefer to "test drive" equipment prior to making an investment. Equipment is often rented when an office is relocating or if funds are unavailable at the time the equipment is needed.
                     
                     </p>
                     <a href='/rental-services' className='home-btn'>Read More</a>

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supplement Distribution</h3>
                    <p>At ELK'S Medicals Limited, you will find exclusively USA high-quality health supplements. Join our network pharmacies, clinics & wellness facilities across Uganda. Explore our fastest growing sector.</p>
                   
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div> */}
           </div>

    </section>
  )
}

export default Services