import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
// import CustomForm from './customForm';

const Subscribe = () => {
    // let postUrl = 'https://gmail.us21.list-manage.com/subscribe/post?u=b670e2de42f6f1022113ef86d&amp;id=26f3e18b72&amp;f_id=009d57e1f0'
  return (
    <div >
        <h3 className="newsletters-h3">Subscribe to our newsletters!</h3> 
        
            <a href="http://eepurl.com/iq6r1I" target='_blank'className="subscribe-btn" rel='noreferrer'>Subscribe</a>
        
        {/* <MailchimpSubscribe url={postUrl} render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}></MailchimpSubscribe> */}
        
    </div>
  )
}
export default Subscribe


