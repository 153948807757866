import React, {useEffect} from 'react'
import Tool1 from '../components/Tool1';
import Tool2 from '../components/Tool2';
import Tool3 from '../components/Tool3';
import Tool4 from '../components/Tool4';
import Tool5 from '../components/Tool5';
import Tool6 from '../components/Tool6';
import Tool7 from '../components/Tool7';
import Tool8 from '../components/Tool8';
import Tool9 from '../components/Tool9';
import Tool10 from '../components/Tool10';

const Gallery = () => {
  useEffect(()=>{
document.title = 'Newsletter | St. Augustine Community Health';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Newsletter</h2>
      <div className='content-container gallery'>
                <object type='application/pdf' data='./docs/Newsletter.pdf' className='pdf-document'>

                </object>
      </div>

    </section>
  )
}

export default Gallery