import Carousel from 'react-bootstrap/Carousel';

function RoadCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/50.jpg"
          alt="First slide"
        />

      </Carousel.Item>
    </Carousel>
  );
}

export default RoadCarousel;