import React, {useEffect} from 'react';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';
import {Link} from 'react-router-dom';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


const Services = () => {

useEffect(()=>{
document.title = "Our Projects | St. Augustine Community Health";

},[]);


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Projects</h2>
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3 projects-h3'>St. Augustine Medical Centre - Mbarara</h3>
           
                     <p>

    {/* We have brought you both new and refurbished medical equipment to your doorsteps in Uganda. We understand the convergence point between high-quality medical equipment & affordability. You'll find both at ELK'S Medicals Limited. */}
            
                     </p>
                      <Link to='/samc' className='home-btn' onClick={scrollFunc}>See Details</Link>
                  </article>
                 

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>
 <h2 className="home-h2 services-h2 projects-sub-heading">Our Funded Projects</h2>
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <p>
                      <strong>Mbarara Do It</strong> project funded by Safe abortion action fund (SAAF) is a three and half year project which will end in December 2025 
                      </p>
                      <Link to='/mbarara-do-it' className='home-btn' onClick={scrollFunc}>See Details</Link>
                     <p>
                {/* At ELK'S Medicals Limited, you'll find a robust outsourcing network & logistics infrastructure especially on refurbished medical equipment from The USA. */}
                     
                     </p>
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    {/* <h3 className='title-h3 service-h3'> </h3> */}
                       
                       <p>
              <strong>Reach Every Woman in my Community (REWOCO)</strong> funded by PSI Uganda
                     
                     </p>
                     <Link to='/rewoco' className='home-btn' onClick={scrollFunc}>See Details</Link>

                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    {/* <h3 className='title-h3 service-h3'>Supplement Distribution</h3> */}
                    <p>
                     To promote access to HIV/AIDS and TB care and treatment to 200 Key Populations [CSW: 170; MSM: 20; IDU: 10] in Ruti ward and Katete ward Mbarara city by December 2022 a training grant funded by TASO Uganda
                      </p>
                      <Link to='/hiv-aids-tb-care' className='home-btn' onClick={scrollFunc}>See Details</Link>
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services