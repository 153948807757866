import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import VisionCarousel from '../components/Vision Carousel';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

// let scrollFunc = () =>{
// window.scrollTo({
//    top:0,
// behavior: "smooth",
// });

// }

const Home = () => {



  useEffect(()=>{
document.title = "HIV/AIDS and TB Care  | St. Augustine Community Health";

},[]);

  return (
    <>
    {/* <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section> */}
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">
            HIV/AIDS and TB Care
          </h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>
          <h3 className='title-h3 service-h3 projects-h3'>Project Goal</h3>

                 <p className='home-p'>
                 The project Goal is to contribute to the promotion and access to HIV/AIDS and TB care and treatment to Key Populations (CSW, MSM, IDU) in Mbarara city by December 2022.
                 </p>
                 <h3 className='title-h3 service-h3 projects-h3'>Objectives</h3>

                 <p className='home-p'>
               <strong> Objective 1:</strong> To facilitate 200 Key Populations among Key Populations in Ruti ward and Katete ward Mbarara City to access HIV testing services by December 2022. 
                 </p>
                 <p className='home-p'>
                        1.0	Conduct an inception meeting with stake holders of the health facilities of Ruti and Nyamitanga wards.
                 </p>
                 <p className='home-p'>
                        1.1	Conduct training of 10 community mobilisers in targeting, sensitization, and referral of KPs for HIV/TB testing.
                 </p>
                 <p className='home-p'>
                        1.2	Conduct monthly HIV/AIDS/TB sensitization meetings among KPs.
                 </p>
                 <p className='home-p'>
                        1.3 Monthly distribution and dissemination of IEC materials on HIV/TB prevention, care and treatment among.
                 </p>
                 <p className='home-p'>
                        1.4 Conduct two radio talk shows on creating awareness about HIV/TB prevention among KPs.
                 </p>
                  <p className='home-p'>
               <strong> Objective 2:</strong> 
              To promote HIV/AIDS care and treatment services among Key Populations in Ruti ward and Katete ward Mbarara City to access HIV testing services by December 2022.
                 </p>
                 <p className='home-p'>
                        2.0	Facilitate referral and linkages of all KPs for PREP and ART care.
                 </p>
                 <p className='home-p'>
                        2.1	Conduct targeted monthly follow up visits.
                 </p>
                 <p className='home-p'>
                        2.2	conduct monthly peer counselling among HIV positive KPs to promote adherence and reduce stigma and discrimination. The project shall facilitate HIV-positive KPs to sensitize other.
                 </p>
                 <p className='home-p'>
                       <strong>Targeting Populations – beneficiaries/clients: Key populations</strong> - CSWs (75%), MSMs (10%) & IDUs (15%).
                 </p>
                  <p className='home-p'>
                    <strong> HIV/AIDS service provision sites/venues</strong> 
                 </p>
                 <ul className='unordered-list'>
                    <li>Public health facilities within the city.</li>
  <li>Private -not-for profit health facilities within the city.</li>
  <li>Private health facility (St. Augustine Medical Centre, Mbarara).</li>
  <li>Sensitization and referral made from communities.</li>

                 </ul>

        </div>

    </section>
<div className='vision-mission-content vision-mission-content-a vision-mission-content-b'>
                    {/* <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article> */}

                    <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/61.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p>A community targeted VCT session taking place at a bar in Katete Mbarara city targeting KPs and their clients as they come in</p>

                    </article>
                    <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/62.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p> FSW registering as a health workers waits to give them PrEP drugs</p>

                    </article>
                     <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/63.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p>  A VCT session ongoing</p>

                    </article>
                    
                

                 </div>
  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.62), rgba(8, 6, 4, 0.81)), url("./images/8.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Mission</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 To promote access to quality sexual and reproductive health services and information to the underserved communities through capacity building, systems strengthening and empowerment.
</p>
        </div>
    </section> */}
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
    
    
  
    </>
  )
}

export default Home