import Carousel from 'react-bootstrap/Carousel';

function MaintCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/54.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
     
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/55.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

    <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/56.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/57.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/58.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/59.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/60.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

      

    </Carousel>
  );
}

export default MaintCarousel;