import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import VisionCarousel from '../components/Vision Carousel';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

// let scrollFunc = () =>{
// window.scrollTo({
//    top:0,
// behavior: "smooth",
// });

// }

const Home = () => {



  useEffect(()=>{
document.title = "REWOCO | St. Augustine Community Health";

},[]);

  return (
    <>
    {/* <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section> */}
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">
            REWOCO
          </h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>
          <h3 className='title-h3 service-h3 projects-h3'>Purpose</h3>

                 <p className='home-p'>
                 To promote complete safe medical abortion services and information to 500 women and girls through community-based approaches in Mbarara city, Mbarara, Isingiro and Sheema districts by November 2023.
                 </p>
                 <h3 className='title-h3 service-h3 projects-h3'>Objective</h3>

                 <p className='home-p'>
                The objective  is to improve access to medical abortion services and information for women and girls in Ntungamo, Rwampala, Isingiro and Sheema districts by November 2023.
This will be through identifying, recruiting and accrediting 15 drug shop workers and 10 community champions. Bulding their capacity through a VCAT training on SA and provision of MA drugs.

                 </p>
        </div>

    </section>
<div className='vision-mission-content vision-mission-content-a vision-mission-content-b'>
                    {/* <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article> */}

                    <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/54.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p>Group work in session</p>

                    </article>
                    <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/55.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p> Group presentation during a training of companions on SMMA</p>

                    </article>
                     <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/56.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p>  Team Lead training the champions on SMMA</p>

                    </article>
                     <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/57.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p> Female condom demonstration in progress</p>

                    </article>
                    <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/58.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p> Our last group of Champions (drug shop attendants) in a group photo after the SMMA training</p>

                    </article>
                    <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/59.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p> Our first group of champions to be trained in SSMA the Champions in a group photo </p>

                    </article>
                    <article className='vision-mission-info vision-mission-info-b'>
                      
                          {/* <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>Quality sexual reproductive health services for all.</p> */}
                          <img src='./images/59.jpg' alt='image' className='founders-image'></img>
                          {/* <h3 className='title-h3 title-h3-a'>Dr. Ampaire Justus</h3> */}
                          {/* <div className='vm-line'> </div> */}
                          <p> VCAT training in session crossing the line with May Namukwaya PSI Uganda </p>

                    </article>

                 </div>
  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.62), rgba(8, 6, 4, 0.81)), url("./images/8.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Mission</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 To promote access to quality sexual and reproductive health services and information to the underserved communities through capacity building, systems strengthening and empowerment.
</p>
        </div>
    </section> */}
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
    
    
  
    </>
  )
}

export default Home